import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class TokenService {
   
   // *local  token
    // bearer_token:string = "Bearer a88c7f7e-03e8-4fb9-a517-5924c268bfea";
   //* CRM token
     // bearer_token:string = "Bearer    c4dd8db1-b5cf-463b-867b-01e992703a37";
   //* uat token
     bearer_token:string = "Bearer  fbf6e7c7-db9e-407f-80d7-02f66eeaf276"; 
     
  constructor() { }
}
    




